import React from 'react';

import Layout from '../components/Layout';

const Thanks = () => (
  <Layout>
    <section className="bg-primary">
      <div className="container">
        <div className="flex flex-wrap justify-center py-16">
          <div className="w-full md:w-4/5 lg:w-1/2 bg-black p-12">
            <p className="text-center mb-0 text-white">
              Thank you for your interest in Ridge Runner Pipeline Commissioning Specialists. Your form submission has
              been received.
            </p>
          </div>
        </div>
      </div>
    </section>
  </Layout>
);

export default Thanks;
